
// @flow

import { buildUrl, } from '../../utils/buildImgURLs';

export const formatTime = (totalSeconds: number) => {
  if (totalSeconds < 0 || totalSeconds === Infinity) {
    return '-- : --';
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const m = hours && minutes < 10 ? `0${minutes}` : minutes;
  const s = seconds < 10 ? `0${seconds}` : seconds;

  return `${hours ? `${hours}:` : ''}${m}:${s}`;
};

export const setDiameter = (prop: any, value: any,) => ({
  width: value,
  height: value,
});


export const getAudioLinkWebView = ({
  image,
  mobileTitle,
  channelLabel,
  channelLinks,
  fileUrl,
}: {
  fileUrl?: string,
  mobileTitle?: string,
  channelLabel?: string,
  channelLinks: ?{
    google: string,
    apple: string,
  },
  image?: Object,
  }) => {
  if (!fileUrl) {
    console.error('Audio Link: URL of file doesn\'t exist');
    return '';
  }

  let href = `${fileUrl}?`;

  if (mobileTitle) {
    href += `title=${encodeURIComponent(mobileTitle)}`;
  }

  if (channelLabel) {
    href += `&exclusive=${encodeURIComponent(channelLabel)}`;
  }

  if (channelLinks && channelLinks.apple) {
    href += `&register_apple=${encodeURIComponent(channelLinks.apple)}`;
  }

  if (channelLinks && channelLinks.google) {
    href += `&register_android=${encodeURIComponent(channelLinks.google)}`;
  }

  if (image) {
    const { contentId, files, } = image || {};
    if (files?.[0]) {
      const imgUrl = buildUrl(
        contentId,
        files[0],
        {
          width: '597',
          height: '597',
          aspect: 'square',
          ...(files?.[0]?.aspects?.square || {}),
        },
      );

      href += `&img=${encodeURIComponent(imgUrl)}`;
    }
  }

  return href;
};
